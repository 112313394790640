import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { containerStyles, searchContainer } from './homepage-navbar.module.style';
import NavbarProfile from '../navbar-profile/navbar-profile';
import { Typography } from '@vc-workspace/utils-typography';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { useContext, useEffect, useState } from 'react';
import { NavItemsLabel } from '../mobile-bottom-navbar/mobile-bottom-navbar';
import NavbarCheckout from '../navbar-checkout/navbar-checkout';
import NavbarWishlist from '../navbar-wishlist/navbar-wishlist';
import DropdownSearch from '../dropdown-search/dropdown-search';
import { AppDownloadMobileBanner } from '../app-banners/download-app';
import { BannerVisibility } from '@vc-workspace/utils-helpers';
export function HomepageNavbar(props) {
  var _useContext = useContext(AppConfigContext),
      setSelectedTab = _useContext.setSelectedTab,
      setIsReferAndEarnOpen = _useContext.setIsReferAndEarnOpen;

  var _useState = useState(true),
      isBanner = _useState[0],
      setIsBanner = _useState[1];

  function onSearchClick() {
    setSelectedTab(NavItemsLabel.EXPLORE);
  }

  function hideBanner() {
    BannerVisibility.set(false);
    setIsBanner(false);
  }

  function onReferAndEarnClick() {
    window.location.href = '/profile/referral?source=navbar';
  }

  useEffect(function () {
    setIsBanner(BannerVisibility.get());
  }, []);
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      css: containerStyles,
      className: !props.context.isMobileView || !isBanner ? "pt-5" : "",
      children: [props.context.isMobileView && isBanner && _jsx(AppDownloadMobileBanner, {
        onClose: hideBanner,
        source: 'MOBILE_NAVBAR',
        context: props.context
      }), _jsxs("div", {
        className: "page-container pure-g",
        children: [_jsx("div", {
          className: props.context.isMobileView ? 'pure-u-1-3 flex align-middle' : 'pure-u-1-5',
          children: props.context.isMobileView ? _jsx("img", {
            src: "https://vcp-provider-media.s3.ap-south-1.amazonaws.com/logo/VC_Logo_110px.svg"
          }) : _jsx("img", {
            src: "https://vcp-provider-media.s3.ap-south-1.amazonaws.com/logo/VC_Logo_130px.svg"
          })
        }), _jsxs("div", {
          className: "".concat(props.context.isMobileView ? 'pure-u-2-3' : 'pure-u-4-5', " flex items-center justify-end"),
          children: [props.context.isMobileView ? _jsx(_Fragment, {
            children: _jsxs("div", {
              css: searchContainer,
              onClick: onSearchClick,
              children: [_jsx("img", {
                src: "/_mp-images/search_white.svg"
              }), _jsx(Typography, {
                type: "Body2",
                color: "white",
                className: "pl-1",
                children: "Search"
              })]
            })
          }) : null, !props.context.isMobileView ? _jsxs(_Fragment, {
            children: [_jsx("div", {
              className: "mr-4 inline-block",
              children: _jsx(DropdownSearch, {
                disableBackdrop: true,
                isImmersive: true
              })
            }), _jsx(NavbarWishlist, {
              context: props.context,
              whiteTheme: true
            }), _jsx(NavbarCheckout, {
              context: props.context,
              whiteTheme: true
            }), _jsx("div", {
              className: "inline-block ml-4",
              children: _jsx(NavbarProfile, {
                context: props.context,
                whiteTheme: true
              })
            })]
          }) : null]
        })]
      })]
    }), _jsx("div", {
      className: isBanner && props.context.isMobileView ? "mb-20" : "mb-6"
    })]
  });
}
export default HomepageNavbar;