import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { ImageAccessibility, ImageTypes } from "@vc-workspace/utils-constants";
import { getImagesByType } from "@vc-workspace/utils-helpers";
import { Typography } from "@vc-workspace/utils-typography";
import { containerStyles, bannerImage, contentContainer } from './homepage-hero-banner.module.style';
import HomepageCategoryWidget from "../homepage-category-widget/homepage-category-widget";
import { useContext } from "react";
import { CategoryContext } from "../global-context-wrapper/global-context-wrapper";
/* eslint-disable-next-line */

export function HomepageHeroBanner(props) {
  var _props$data$data$cura;

  var _useContext = useContext(CategoryContext),
      curatedCategories = _useContext.curatedCategories;

  var displayImage = getImagesByType(props.data.data.banners[0].images, ImageTypes.HomePageBanner, "3", props.context.isMobileView ? ImageAccessibility.Mobile : ImageAccessibility.Desktop);
  return _jsxs("div", {
    css: containerStyles,
    style: !curatedCategories.length ? props.context.isMobileView ? {
      marginTop: '-98px !important'
    } : {
      marginTop: '-117px !important'
    } : {},
    children: [_jsxs("picture", {
      children: [_jsx("source", {
        type: "image/webp",
        srcSet: displayImage.webP
      }), _jsx("img", {
        src: displayImage.url,
        css: bannerImage
      })]
    }), _jsx("div", {
      css: contentContainer,
      children: _jsxs("div", {
        className: "page-container",
        children: [_jsx(Typography, {
          type: props.context.isMobileView ? "Headline_H2" : "Heading2",
          color: "white",
          className: "md:pb-20 pb-6 md:text-center",
          bold: !props.context.isMobileView,
          semi_bold: props.context.isMobileView,
          children: _jsx("p", {
            dangerouslySetInnerHTML: {
              __html: props.data.data.banners[0].bannerText
            }
          })
        }), _jsx(HomepageCategoryWidget, {
          categories: ((_props$data$data$cura = props.data.data.curatedCategories) === null || _props$data$data$cura === void 0 ? void 0 : _props$data$data$cura.data) || [],
          context: props.context
        })]
      })
    })]
  });
}
export default HomepageHeroBanner;